import React from 'react';
import ReactDOM from 'react-dom';
import WingPayPopUp from "./WingPayPopUp";
import {RecoilRoot} from "recoil";

/**
 * user = {
 *          name : 구매자 이름,
 *          email : 구매자 이메일,
 *          phone : 구매자 핸드폰
 *         }
 * product = [{
 *              prodId : 상품 고유 id,
 *              name : 상품 이름,
 *              qty : 상품 개수,
 *              price : 상품 가격,
 *              desc : 상품 추가 설명 (선택)
 *           }]
 * card = {
 *          number : 카드번호,
 *          expiry : 카드 유효기간,
 *          cvv : CVV 번호,
 *          authPw : 비밀번호 앞 두자리,
 *          authDob : 생년월일
 *       }
 * @param user
 * @param product
 * @param card
 * @constructor
 */
function Index(id, user, product, card,
               order,
               orderInfo, movePage) {
  const handlePopupClose = () => {
    const popupElement = document.getElementById('popup-container');
    ReactDOM.unmountComponentAtNode(popupElement);
    popupElement.remove();
  };

  const popupElement = document.createElement('div');
  popupElement.id = 'popup-container'; // 팝업을 렌더링할 컨테이너의 ID를 설정합니다.
  document.getElementById('popupRoot').appendChild(popupElement);

  ReactDOM.render(
    <RecoilRoot>
      <WingPayPopUp
        id={id}
        onClose={handlePopupClose}
        userInfo={user}
        cardInfo={card}
        productInfo={product}
        order={order}
        orderInfo={orderInfo}
        movePage={movePage}
      />
    </RecoilRoot>
    , popupElement); // onClose prop을 전달하여 팝업이 닫힐 때 호출되는 함수를 설정합니다.
}

export default Index;
