const config = {
    WIN_GLOBAL_PAY : {
        API_KEY: 'pk_97f5-67668d-3d5-2518b',
        GID: 'hjyou02',
        TID: 'WGP325100',
        SERIAL_NUMBER: '735291'
    }
}

export default config

/**
 * [여기콕]
 * 1. https://yeogicock.co.kr/shop/detail/10
 * 2. https://yeogicock.co.kr/shop/detail/9
 * 온라인 key : pk_97f5-67668d-3d5-2518b
 * GID : hjyou02
 * TID : WGP325100
 * 일련번호 : 735291
 */