import axios from 'axios'

const WingService = {
  echo: async (headers) => {
    try {
      const response = await axios.post(
        'https://api.winglobalpay.com/api/echo',
        {},
        { headers }
      );
      return response.data.result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  pay: async (headers, payload) => {
    try {
      const response = await axios.post(
        'https://api.winglobalpay.com/api/pay',
        { ...payload },
        { headers }
      );
      console.log(response.data)
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
};

export default WingService;
